import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserProvider } from "./context/userContext";
import MainContent from "./MainContent"; // Import the separated MainContent

function App() {
  return (
    <div>
      <UserProvider>
        <BrowserRouter>
          <MainContent /> {/* MainContent now contains routes and layout */}
          <ToastContainer autoClose={5000}/>
        </BrowserRouter>
      </UserProvider>
    </div>
  );
}

export default App;
