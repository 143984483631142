import React from "react";
import losingImg from '../../assets/loseImg.png'

const LosingModal = ({ setShowModal }) => {
  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex justify-center items-center w-full h-screen bg-black bg-opacity-50"
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={() => setShowModal(false)}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-4 md:p-5 text-center flex-col justify-center items-center ">
            <img src={losingImg} alt="winning" className="mx-auto" />
            <h1
              className="mb-2 text-xl font-bold text-gray-500 dark:text-gray-400"
              style={{ color: "#FF0000" }}
            >
              نعتذر منك يالـامير
            </h1>
            <div
              className=" text-lg font-semibold text-gray-500 dark:text-gray-400"
              style={{ color: "#274263" }}
            >
              تعوض في المزادات الجاية
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LosingModal;
